@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap') format('woff2');
    }
  }
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f0f0f0; /* Track color */
}

::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 4px; /* Roundness of the scrollbar */
    border: 2px solid transparent; /* Optional: Create space around thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color on hover */
}

/* Firefox scrollbar styling */
.scrollbar {
    scrollbar-width: thin; /* Thin scrollbar for Firefox */
    scrollbar-color: #888 #f0f0f0; /* Scrollbar color and track color */
}
